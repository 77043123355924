$(function() {
    if ($("#map").length != 0) {
        ymaps.ready(init);

        function init() {
            var myMap = new ymaps.Map("map", {
                center: [56.859611, 35.911896],
                controls: [],
                zoom: 14
            });

            myMap.behaviors.disable("scrollZoom");
            //myMap.behaviors.disable("drag");
            myMap.controls.add(new ymaps.control.ZoomControl());

            var menu = $("#map-menu");

            $.each(filters, function(index, group) {
                var menuItem = $(
                    '<div class="col-md-4 map-menu__item js-menu-item">' +
                        '<a class="map-menu__link" href="#">' +
                        group.title +
                        "</a>" +
                        "</div>"
                );

                var clusterer = new ymaps.Clusterer({
                    preset: 'islands#redClusterIcons',
                    groupByCoordinates: false,
                    clusterDisableClickZoom: false,
                    clusterHideIconOnBalloonOpen: false,
                    geoObjectHideIconOnBalloonOpen: false
                });

                // Создадим коллекцию для геообъектов группы.
                var collection = new ymaps.GeoObjectCollection(null, {
                    preset: group.preset
                });

                // открываем все метки
                if (group.name === "all") {
                    //myMap.geoObjects.add(collection);
                    myMap.geoObjects.add(clusterer);
                    menuItem.addClass("map-menu__item--active");
                }

                menuItem.appendTo(menu);

                $(".js-menu-item").on("click", function(e) {
                    e.preventDefault();

                    if ($(this).hasClass("map-menu__item--active"))
                        return false;

                    $(".js-menu-item").removeClass("map-menu__item--active");
                    $(this).toggleClass("map-menu__item--active");

                    myMap.geoObjects.removeAll();

                    if (collection.getParent()) {
                        //myMap.geoObjects.remove(collection);
                        myMap.geoObjects.remove(clusterer);
                    } else {
                        //myMap.geoObjects.add(collection);
                        myMap.geoObjects.add(clusterer);
                    }
                });

                $.each(items, function(index, item) {
                    var MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
                        '<div class="circle-marker">' +
                            '<div class="circle-marker__point">' +
                            '<img src="$[properties.iconContent]" alt="">'+
                            '</div>' +
                            '<div class="circle-marker__content"><span>$[properties.iconCaption]<span></div>' +
                            "</div>"
                    );

                    if (group.name === item.type || group.name === "all") {
                        var placemark = new ymaps.Placemark(
                            item.coords,
                            {
                                balloonContentHeader: item.properties.balloonContentHeader,
                                balloonContentBody: item.properties.balloonContentBody,
                                iconCaption: item.properties.iconContent,
                                iconContent: item.properties.iconContent
                            },
                            {
                                iconLayout: "default#imageWithContent",
                                iconImageHref: "site/templates/static/img/map/circle-marker.svg",
                                iconImageSize: [40, 40],
                                iconImageOffset: [-20, -20],
                                iconContentOffset: [0, 0],
                                //iconContentLayout: item.properties.iconContent
                            }
                        );
                        //collection.add(placemark);
                        clusterer.add(placemark);
                    }
                });
            });

            // Выставляем масштаб карты чтобы были видны все группы.
            myMap.setBounds(myMap.geoObjects.getBounds());
        }
    }
});
