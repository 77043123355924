$(function() {
  function windowHeight() {
    var content = $(".js-content");
    var contentChild = $(".js-row");
    var header = $(".section--header");
    var arrows = $(".arrows");

    content.removeClass("full-height");
    contentChild.removeClass("full-height");

    if ($(window).height() < (content.innerHeight() + 25)) {
      header.removeClass("screen-height");
      content.addClass("full-height");
      contentChild.addClass("full-height");
      arrows.addClass("arrows--none");
    } else {
      header.addClass("screen-height");
      content.addClass("full-height");
      contentChild.addClass("full-height");
      arrows.removeClass("arrows--none");
    }
  }

  //windowHeight();
  //$(window).resize(windowHeight);
});
