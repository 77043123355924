$(function() {
    function windowSizeAppend() {
        /* if ($(window).width() <= '575') {
        $('.section--header .button--callback').appendTo($('.section--header .js-get'));
    } else {
        $('.section--header .button--callback').appendTo($('.section--header .js-point'));
    }

    if ($(window).width() <= '767') {
        $('.js-form #name-error').appendTo($('.js-form .js-error-alert'));
        $('.js-form #phone-error').appendTo($('.js-form .js-error-alert'));
        $('.js-form-footer #name-error').appendTo($('.js-form-footer .js-error-alert'));
        $('.js-form-footer #phone-error').appendTo($('.js-form-footer .js-error-alert'));
    } else {
        $('.js-form #name-error').appendTo($('.js-form .js-group-name'));
        $('.js-form #phone-error').appendTo($('.js-form .js-group-phone'));
        $('.js-form-footer #name-error').appendTo($('.js-form-footer .js-group-name'));
        $('.js-form-footer #phone-error').appendTo($('.js-form-footer .js-group-phone'));
    } */

        if ($(window).width() <= 991) {
            $(".section--header .js-menu-left").appendTo($(".menu-mobile"));
            $(".section--header .js-menu-right").appendTo($(".menu-mobile"));
        } else {
            $(".section--header .js-menu-left").appendTo(
                $(".section--header .js-nav")
            );
            $(".section--header .js-menu-right").appendTo(
                $(".section--header .js-nav")
            );
        }
    }

    windowSizeAppend();
    $(window).resize(windowSizeAppend);
});
