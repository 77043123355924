$(document).ready(function() {

	var form = $("#formContentBlocks");
	var submitButton = form.find('#callform-submit');
	var msgContainerName = "#formAlertBox";
	var msgContainer = $(msgContainerName);
		
	function showFormAlert(status, msg) {
		var removeStatus = 'error';

		if (status == 'error') {
			removeStatus = 'success';
		}

		msgContainer.removeClass("alert--" + removeStatus)
			.html('')
			.addClass("alert--" + status)
			.addClass('jsCheckSendClass')
			.html(msg)
			.show();
	};

	// Setup phone masked input
	$("[name='phone']").inputmask({
		mask: "+7 (9##) ###-##-##",
		autoUnmask: true,
		showMaskOnHover: false
	});

	// Setup form validation with
	form.validate({
		ignore: ":hidden:not(.js-input-ignore-not)",
		errorClass: "input--error",
		errorContainer: msgContainerName,
		errorElement: "span",
		errorLabelContainer: msgContainerName,
		showErrors: function(errorMap, errorList) {

			if (msgContainer.hasClass("jsCheckSendClass")) {
				msgContainer.html('').removeClass("jsCheckSendClass");
			}

			msgContainer.removeClass("alert--success").addClass("alert--error");
			this.defaultShowErrors();
		},

		rules: {
			name: {
				required: true,
				minlength: 2
			},
			phone: {
				required: true,
				digits: true,
				minlength: 10
			},
			email: {
				email: true,
				required: true
			},
			agree: "required"
		},

		messages: {
			name: {
				required: "Введите имя.<br>",
				minlength: "Имя должно состоять как минимум из 2-х символов.<br>"
			},
			phone: {
				required: "Введите номер телефона.<br>",
				minlength: "Номер телефона должен состоять как минимум из 10-ти символов.<br>"
			},
			email: "Введите корректный адрес электронной почты.<br>",
			agree: {
				required: "Необходимо Ваше согласие с политикой конфедициальности.<br>"
			}
		},

		submitHandler: function(form) {
			submitButton.prop("disabled", true);

			// Ajax submit via https://github.com/jquery-form/form
			$(form).ajaxSubmit({
				url: "/ajax-handler/",
				type: "post",
				dataType: "json",
				data: {
					formType: $(form).attr('data-form-type'),
					formId: $(form).attr('data-form-id'),
				},
				success: function(data) {

					if(data['status']) {
						var msg = '';
						$(form).clearForm();
						msg = 'Ваше сообщение отправлено. Мы свяжемся с Вами в ближайшее время.';
						showFormAlert('success', msg);
						// yaCounter47671570.reachGoal('datatransferred');
					} else {
						msg = 'Сообщение не было отправлено по техническим причинам. Пожалуйста, позвоните нам по телефону.';
						showFormAlert('error', msg);
					}
				},
				error: function(error) {
					var msg = 'Сообщение не было отправлено по техническим причинам. Пожалуйста, позвоните нам по телефону.';
					showFormAlert('error', msg);
				},
				complete: function() {
					submitButton.prop("disabled", false);
				}
			});
			return false;
		}
	});
});