$(function() {
    $('.js-popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        closeBtnInside: false,
        image: {
            titleSrc: function(item) {
                return item.el.children('img').attr('alt');
            }
        },
        gallery: {
            enabled: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>'
        }
	});
	
    $('.js-popup-slider').magnificPopup({
        delegate: 'a',
        type: 'image',
        closeBtnInside: false,
        image: {
            titleSrc: function(item) {
                return item.el.children('img').attr('alt');
            }
        },
        gallery: {
            enabled: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>'
        }
    });	

    $('.js-popup-slide-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        closeBtnInside: false,
        image: {
            titleSrc: function(item) {
                return item.el.children('img').attr('alt');
            }
        },
        gallery: {
            enabled: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>'
        }
    });	

});
