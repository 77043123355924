$(function () {
    $(".js-callback").on('click', scrolling);
    $(".js-arrows").on('click', scrolling);

    function scrolling(event) {
        event.preventDefault();

        var id = $(this).attr('href'),
            top = $(id).offset().top;

        $('body,html').animate({
            scrollTop: top
        }, 1500);
    }
});