$(function() {

	$('.js-slider-assortment').each(function (index) {
		const swiper = new Swiper($(this)[0],{
			effect: "fade",
			crossFade: true,
			preventClicks: false,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				bulletClass: 'assortment__swiper-pagination-bullet',
			},
		});
	});

	$('.assortment__swiper-pagination-bullet').hover(function() {
		$(this).trigger("click", "paginationBulletHoverClick");
	});

	$('.assortment__swiper-pagination-bullet').click(function(event, context) {
		if(context != "paginationBulletHoverClick") {
			href = $(this).parents(".assortment__link").first().attr("href");
			window.location.href = href;
		}
		
	});

});