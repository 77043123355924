if ($(".map-vue").length != 0) {
	var mapVue = new Vue({
		el: ".map-vue",
		data: {
			coords: [56.859611, 35.911896],
			selectFilter: "all",
			search: "",
			selectedRegion: "Тверская область",
			selectedCity: "Все",
			getFilteredList: "",

			templateMarer: [],
			controls: ["zoomControl"],
			behaviors: ["drag"],
			options: {
				iconLayout: "default#imageWithContent",
				iconImageHref: "site/templates/static/img/map/circle-marker.svg",
				iconImageSize: [40, 40],
				iconImageOffset: [-20, -20],
				iconContentOffset: [0, 0]
			},

			filters,
			items,
			regions,
			cities
		},

		mounted() {
			this.getFilteredList = this.items.filter(this.filterItem);
		},

		methods: {
			initHandler(map) {
				this.map = map;
				setTimeout(() => {
					map.setBounds(map.geoObjects.getBounds()).then(() => {
						if (map.getZoom() > 14) map.setZoom(14);
					});
				}, 300);
				map.behaviors.disable("scrollZoom");
			},

			autopod() {
				return (this.selectedCity = "Все");
			},

			shouldDisplay(region) {
				return this.selectedRegion === region;
			},

			filterItem(item) {
				var filter = new RegExp(this.search, "i");
				if (this.selectFilter === "all") {
					if (this.selectedCity === "Все") {
						return (
							item.region.match(this.selectedRegion) &&
							item.street.match(filter)
						);
					} else {
						return (
							item.region.match(this.selectedRegion) &&
							item.city.match(this.selectedCity) &&
							item.street.match(filter)
						);
					}
				} else {
					if (this.selectedCity === "Все") {
						return (
							item.type.match(this.selectFilter) &&
							item.region.match(this.selectedRegion) &&
							item.street.match(filter)
						);
					} else {
						return (
							item.type.match(this.selectFilter) &&
							item.region.match(this.selectedRegion) &&
							item.city.match(this.selectedCity) &&
							item.street.match(filter)
						);
					}
				}
			},

			filteredList() {
				this.getFilteredList = this.items.filter(this.filterItem);
			}
		}
	});
}
