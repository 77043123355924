$(function() {
    $(".js-slider").slick({
        infinite: true,
        arrows: true,
        prevArrow: '<img class="slick-prev" src="/site/templates/static/img/slider/arrow.svg">',
        nextArrow: '<img class="slick-next" src="/site/templates/static/img/slider/arrow.svg">',
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: "5px",
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
});
