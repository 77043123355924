$(function() {
    if ($("#map-contacts").length != 0) {
        ymaps.ready(init);

        function init() {
            var myMap = new ymaps.Map("map-contacts", {
                center: [56.702777, 35.759847],
                controls: [],
                zoom: 14
            });

            myMap.behaviors.disable("scrollZoom");
            myMap.behaviors.disable("drag");
            myMap.controls.add(new ymaps.control.ZoomControl());

            var MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
                '<div class="circle-marker">' +
                    '<div class="circle-marker__point">' +
                        '<div class="circle-marker__center"></div>' +
                    "</div>" +
                "</div>"
            );

            var placemark = new ymaps.Placemark(
                [56.702777, 35.759847],
                {
                    balloonContent: "Тест"
                },
                {
                    iconLayout: "default#imageWithContent",
                    iconImageHref: "site/templates/static/img/map/circle-marker.svg",
                    iconImageSize: [40, 40],
                    iconImageOffset: [-20, -20],
                    iconContentOffset: [0, 0],
                    iconContentLayout: MyIconContentLayout,
                    hideIconOnBalloonOpen: false
                }
            );
            myMap.geoObjects.add(placemark);
            //myMap.setBounds(myMap.geoObjects.getBounds());
        }
    }
});
