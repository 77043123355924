/* $(function() {
    var toggles = document.querySelectorAll(".js-mobile-menu-toggler");
    var humburger = document.querySelector(".toggle-humburger");

    for (var i = toggles.length - 1; i >= 0; i--) {
        var toggle = toggles[i];
        toggleHandler(toggle);
    }

    function toggleHandler(toggle) {
        toggle.addEventListener("click", function(e) {
            e.preventDefault();
            if (humburger.classList.contains("active") === true) {
                humburger.classList.remove("active");
            } else {
                humburger.classList.add("active");
            }
        });
    }
}); */
