$(function() {

	productImagesSlider = new Swiper('.js-slider-ware', {
		pagination: {
			el: ".swiper-pagination",
		},
	});

	productImagesSlider.on('slideChange', function () {
		$(".ware__images-slider-control").removeClass("ware__images-slider-control--active");
		$("[data-slide='" + this.activeIndex + "'].ware__images-slider-control").addClass("ware__images-slider-control--active");
	});

});